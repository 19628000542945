// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/modules/membership/config.ts"
);
import.meta.hot.lastModified = "1731679026364.643";
}
// REMIX HMR END

export const config = {
  path: '/dashboard/memberships',
  apiPath: '/api/membership',
}
